import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { InputText } from '@components/atomic/inputs/InputText';
import { useContext } from 'react';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import {
  getContractPrice,
  updateSimulationPrice,
} from '@models/worksiteCreation/utils/functions';
import { ISimulatorDataOperation } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ICustomPrice {
  onSelect: (selected: boolean) => void;
  isSelected: boolean;
  operation: ISimulatorDataOperation;
  b2bResidential: boolean;
}
function CustomPrice({
  onSelect,
  isSelected,
  operation,
  b2bResidential,
}: ICustomPrice) {
  const { updateSimulatorDataOperation, readOnly } = useContext(
    WorksiteCreationContext
  );
  const methods = useFormContext();
  const { watch, unregister } = methods;

  const { t } = useTranslation();

  const code = String(operation['operation.id']).toUpperCase();
  const label = `${code} - ${String(operation['operation.name'])}`;

  const updateOperationPrice = (
    val: string,
    precarity: null | 'classic' | 'modest'
  ) => {
    if (precarity) unregister(code);
    // Remplace the coma by a point in the val
    const newVal = val.replace(',', '.');
    updateSimulationPrice(
      code,
      newVal,
      updateSimulatorDataOperation,
      precarity
    ); //
  };

  const inputId = code;
  const inputIdClassic = `classic_${code}`;
  const inputIdModest = `modest_${code}`;
  const getCustomPrice = (precarity: null | 'classic' | 'modest') => {
    switch (precarity) {
      case 'classic':
        return watch(inputIdClassic);
      case 'modest':
        return watch(inputIdModest);
      default:
        return watch(inputId);
    }
  };

  return (
    <div className="border border-borderGrey rounded-default p-3 my-3">
      <div>{label}</div>
      <div className="w-full flex gap-3 mt-3">
        <TextWithRadio
          label={`${t(
            'worksite_creation.simulation.custom_prices.contract_price'
          )}`}
          value=""
          isChecked={!isSelected}
          setSelectedValue={() => !readOnly && onSelect(false)}
          addClass="w-full"
          disabled={readOnly}
        />
        <TextWithRadio
          label={`${t(
            'worksite_creation.simulation.custom_prices.custom_price'
          )}`}
          value=""
          isChecked={isSelected}
          setSelectedValue={() => !readOnly && onSelect(true)}
          addClass="w-full"
          disabled={readOnly}
        />
      </div>
      <div className="flex gap-3 mt-3">
        {!b2bResidential && (
          <InputText
            label={`${t(
              'worksite_creation.simulation.custom_prices.pu_label'
            )}`}
            id={inputId}
            name={inputId}
            dataTestId={inputId}
            typeNumber
            placeholder=""
            value={
              isSelected
                ? getCustomPrice(null)
                : getContractPrice(operation, null)
            }
            required={isSelected}
            disabled={!isSelected || readOnly}
            hideIsOptional
            onChange={(e) => updateOperationPrice(String(e), null)}
          />
        )}
        {b2bResidential && (
          <InputText
            label={`${t(
              'worksite_creation.simulation.custom_prices.pu_classic_label'
            )}`}
            id={inputIdClassic}
            name={inputIdClassic}
            dataTestId={inputIdClassic}
            typeNumber
            placeholder=""
            value={
              isSelected
                ? getCustomPrice('classic')
                : getContractPrice(operation, 'classic')
            }
            required={isSelected}
            disabled={!isSelected || readOnly}
            hideIsOptional
            onChange={(e) => updateOperationPrice(String(e), 'classic')}
          />
        )}
        {b2bResidential && (
          <InputText
            label={`${t(
              'worksite_creation.simulation.custom_prices.pu_modest_label'
            )}`}
            id={inputIdModest}
            name={inputIdModest}
            dataTestId={inputIdModest}
            typeNumber
            placeholder=""
            value={
              isSelected
                ? getCustomPrice('modest')
                : getContractPrice(operation, 'modest')
            }
            required={isSelected}
            disabled={!isSelected}
            hideIsOptional //
            onChange={(e) => updateOperationPrice(String(e), 'modest')}
          />
        )}
      </div>
    </div>
  );
}

export default CustomPrice;
