/* eslint-disable no-case-declarations */
import {
  IGraph,
  IOptionGraph,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import { InputText } from '@components/atomic/inputs/InputText';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useCallback, useContext, useMemo, useState } from 'react';
import { FormAddress } from '@components/formAddress/FormAddress';
import { useTranslation } from 'react-i18next';
import { convertToFormAddressDatas } from '@utils/utils';
import {
  ACTIVITY,
  HABITATION_ZONE,
} from '@models/worksiteCreation/utils/enums';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';

interface IGeneralInformationForm {
  infosGraph: IGraph[] | undefined;
}

function GeneralInformationForm({ infosGraph }: IGeneralInformationForm) {
  const [isManualAddress, setIsManualAddress] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const {
    simulatorData,
    updateSimulatorData,
    worksiteAddress,
    updateWorksiteAddressFromKeyValue,
    readOnly,
  } = useContext(WorksiteCreationContext);

  const { t } = useTranslation();

  const updateSimulatorDataCallback = useCallback(
    (data: any) => {
      updateSimulatorData((prev) => {
        const prevToSet = { ...prev };
        if (data?.['general.energy.system']) {
          delete prevToSet['general.energy.mode']; // Supprimez la clé 'general.energy.mode'
        }
        return {
          ...prevToSet,
          ...data,
        };
      });
    },
    [updateSimulatorData]
  );

  const customInputIds = useMemo(
    () => ({
      streetNumber: 'general.worksStreetNumber',
      street: 'general.worksStreet',
      city: 'general.worksCity',
      zipCode: 'general.zipcode',
      country: 'general.country',
    }),
    []
  );

  const handleDropdownSelectedValue = (
    graphOption: IOptionGraph[],
    key: string
  ): string | undefined => {
    const selectedValue = Object.values(graphOption).find(
      (elt) => elt.label === key
    );
    return selectedValue ? selectedValue.value : undefined;
  };

  const clearSimulatorAddress = (newAddress: string | null) => {
    updateSimulatorData((prevData) => ({
      ...prevData,
      'general.address': newAddress,
    }));
  };

  const renderInput = (graph: IGraph) => {
    switch (graph.key) {
      case 'general.works':
        let displayedAddress = '';
        if (worksiteAddress.numberStreet && worksiteAddress.streetName) {
          displayedAddress = `${worksiteAddress.numberStreet || ''} ${
            worksiteAddress.streetName || ''
          }, ${worksiteAddress.zipCode || ''} ${worksiteAddress.city || ''}`;
        }
        return (
          <div key={graph.key} className="flex flex-col w-full space-y-[.5rem]">
            <div className="text-textGrey text-[.75rem] leading-[.75rem]">
              {t('forms.address.worksites_address')}
            </div>
            <FormAddress
              valueInitialAddress={displayedAddress}
              setIsManual={setIsManualAddress}
              isManual={isManualAddress}
              datas={convertToFormAddressDatas(worksiteAddress)}
              updateDatas={updateWorksiteAddressFromKeyValue}
              customInputIds={customInputIds}
              required={!graph.optional}
              disabled={readOnly}
              clearSimulatorAddress={clearSimulatorAddress}
            />
          </div>
        );
      case 'general.surface':
        return (
          <InputText
            dataTestId={`input_type_number_${graph.key}`}
            typeNumber
            key={graph.key}
            title={graph.name}
            label={graph.name}
            placeholder={graph.name}
            required={!graph.optional}
            valueInitialInput={
              simulatorData[graph.key] !== '0'
                ? simulatorData[graph.key]
                : undefined
            }
            name={graph.key}
            id={graph.key}
            onChange={(e) => {
              if (typeof e === 'string') {
                updateSimulatorDataCallback({
                  [graph.key]: parseFloat(e.replace(',', '.')) || undefined, // Remplacer la virgule par un point pour une conversion correcte
                });
              }
            }}
            disabled={readOnly}
            addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
          />
        );
      case 'general.worksiteName':
        return (
          <InputText
            dataTestId={`input_text_${graph.key}`}
            key={graph.key}
            title={graph.name}
            label={graph.name}
            placeholder={graph.name}
            required={!graph.optional}
            valueInitialInput={simulatorData[graph.key]?.toString() || ''}
            name={graph.key}
            id={graph.key}
            onChange={(e) => {
              updateSimulatorDataCallback({
                [graph.key]: e,
              });
            }}
            disabled={readOnly}
            addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
          />
        );
      case 'general.habitation':
      case 'general.habitationZone':
      case 'general.energy.system':
      case 'general.energy.mode':
      case 'general.energy.supplementalType':
        const arrayValues: IOptionGraph[] = [];
        graph.options?.map((option) =>
          arrayValues.push({ label: option.label, value: option.value })
        );
        return (
          <div key={graph.key} className="flex w-full space-x-2">
            {/* <InputSelect
              addClass="w-full"
              label={`${graph.name} *`}
              placeholder={graph.name}
              dataLabelValue={arrayValues}
              onSelectLabelValue={(e) => {
                updateSimulatorDataCallback({
                  [graph.key]: e.value,
                });
              }}
              valueInput={
                simulatorData[graph.key] !== null &&
                simulatorData[graph.key] !== undefined
                  ? arrayValues.filter(
                      (item: { label: string; value: string }) =>
                        item.value === simulatorData[graph.key]
                    )[0].label
                  : ''
              }
              dataTestIdSelect={`input_select_${graph.key}`}
            /> */}
            <InputSelect
              label={`${graph.name}`}
              dataTestIdSelect={`input_select_${graph.key}`}
              addClass="w-full"
              placeholder={graph.name}
              dataArrayString={
                graph.options
                  ? Object.values(graph.options).map((option) => option.label)
                  : []
              }
              onSelect={(e) => {
                const value = graph.options
                  ? handleDropdownSelectedValue(graph.options, e)
                  : null;
                const simulatorDataValue = { ...simulatorData };
                if (graph.key === 'general.energy.system') {
                  delete simulatorDataValue['general.energy.mode'];
                }
                if (graph.key === 'general.habitationZone') {
                  if (value === HABITATION_ZONE.CONTRACTED) {
                    simulatorDataValue['general.activity'] =
                      ACTIVITY.SOCIAL_LANDLORD;
                  }
                  if (value === HABITATION_ZONE.QPV) {
                    simulatorDataValue['general.activity'] = ACTIVITY.SYNDIC;
                  }
                }
                updateSimulatorData({
                  ...simulatorDataValue,
                  [graph.key]: value,
                });
              }}
              valueInput={
                simulatorData[graph.key] !== null &&
                simulatorData[graph.key] !== undefined &&
                graph.options
                  ? graph.options.find(
                      (option) => option.value === simulatorData[graph.key]
                    )?.label
                  : graph.name
              }
              required={!graph.optional}
              disabled={readOnly}
            />
            {/* permet d'afficher un input [general.energy.mode] sans avoir sélectionné de valeur dans l'input [general.energy.system] */}
            {graph.key === 'general.energy.system' &&
              simulatorData['general.energy.system'] === null &&
              graph.childrens && (
                <InputSelect
                  disabled
                  dataTestIdSelect={`input_select_${graph.childrens[0].key}`}
                  addClass="w-full"
                  label={`${graph.childrens[0].name}`}
                  placeholder={graph.childrens[0].name}
                  required={!graph.optional}
                />
              )}
          </div>
        );
      case 'general.system':
      case 'general.energy':
      case 'general.energy.supplemental':
        return (
          <div key={graph.key} className="mb-[1.5rem]">
            <p className="text text-[.75rem] mb-[.5rem] text-textGrey">
              {graph.name}{' '}
              {graph.optional && <span>({t('global.optional')})</span>}
            </p>
            <div className="flex w-full space-x-[.5rem]">
              {graph.options?.map((option) => (
                <TextWithRadio
                  dataTestId={`input_radio_${graph.key}_${option.value}`}
                  key={option.label}
                  label={option.label}
                  value={option.value}
                  addClass="flex-grow"
                  selectValue={simulatorData[graph.key]?.toString()}
                  setSelectedValue={(e) =>
                    updateSimulatorDataCallback({
                      [graph.key]: e,
                    })
                  }
                  isChecked={simulatorData[graph.key] === option.value}
                  disabled={readOnly}
                />
              ))}
            </div>
          </div>
        );
      case 'general.worksiteStartDate':
        return (
          <div className="flex flex-col w-full space-y-[.5rem]" key={graph.key}>
            <DatePicker
              label={graph.name}
              required
              noDefaultDate
              defaultDate={simulatorData[graph.key]}
              onChangeDate={(e) => {
                setStartDate(e);
                if (endDate && new Date(e) > new Date(endDate)) {
                  setEndDate(''); // Réinitialiser endDate si startDate est postérieure à endDate
                }
                updateSimulatorDataCallback({
                  [graph.key]: e,
                });
              }}
              dataTestId={`date_picker_${graph.key}`}
              // todo disabled
            />
          </div>
        );
      case 'general.worksiteEndDate':
        return (
          <div className="flex flex-col w-full space-y-[.5rem]" key={graph.key}>
            <DatePicker
              label={graph.name}
              required
              defaultDate={simulatorData[graph.key] || startDate}
              minDate={startDate}
              onChangeDate={(e) => {
                setEndDate(e);
                updateSimulatorDataCallback({
                  [graph.key]: e,
                });
              }}
              dataTestId={`date_picker_${graph.key}`}
              // todo disabled
            />
          </div>
        );
      case 'general.owner':
      case 'general.persons':
      default:
        return <div key={graph.key} data-test-id={graph.key} />;
    }
  };

  const nextChildren = (graphs: IGraph[]) => {
    return graphs?.map((graph) => {
      return graph?.childrens?.map((grandchild: IGraph) => {
        const conditionIsOk = grandchild.condition?.includes(
          simulatorData[graph.key]
        );
        return conditionIsOk && renderInput(grandchild);
      });
    });
  };

  const graphsToRender = useMemo(() => {
    if (!infosGraph) return [];

    const graphWorksAddress = infosGraph.filter(
      (graph) => graph.key === 'general.works'
    );

    const graphsWorksiteNameAndHabitationZone = infosGraph.filter(
      (graph) =>
        graph.key === 'general.worksiteName' ||
        graph.key === 'general.habitationZone'
    );

    const graphsOwnerAndPersons = infosGraph.filter(
      (graph) =>
        graph.key === 'general.owner' || graph.key === 'general.persons'
    );

    const graphsHabitationAndSurface = infosGraph.filter(
      (graph) =>
        graph.key === 'general.habitation' || graph.key === 'general.surface'
    );

    const graphsSystemAndEnergy = infosGraph.filter(
      (graph) =>
        graph.key === 'general.system' || graph.key === 'general.energy'
    );

    const graphsEnergySystem = infosGraph.filter(
      (graph) => graph.key === 'general.energy.system'
    );
    const graphsEnergyMode = infosGraph.filter(
      (graph) => graph.key === 'general.energy.mode'
    );
    const graphsEnergySupplemental = infosGraph.filter(
      (graph) => graph.key === 'general.energy.supplemental'
    );
    const graphsEnergySupplementalType = infosGraph.filter(
      (graph) => graph.key === 'general.energy.supplementalType'
    );
    const graphsWorksiteStartDateAndEndDate = infosGraph.filter(
      (graph) =>
        graph.key === 'general.worksiteStartDate' ||
        graph.key === 'general.worksiteEndDate'
    );

    return [
      graphWorksAddress,
      graphsWorksiteNameAndHabitationZone,
      graphsOwnerAndPersons,
      graphsHabitationAndSurface,
      graphsSystemAndEnergy,
      graphsEnergySystem,
      graphsEnergyMode,
      graphsEnergySupplemental,
      graphsEnergySupplementalType,
      graphsWorksiteStartDateAndEndDate,
    ] as IGraph[][];
  }, [infosGraph]);

  return (
    <div className="flex flex-col space-y-[.5rem]">
      {graphsToRender.map((graphs) => {
        let divClass = '';
        if (graphs && graphs.length > 0) {
          if (
            !graphs.some(
              (graph) =>
                graph.key === 'general.system' ||
                graph.key === 'general.energy' ||
                graph.key === 'general.energy.supplemental'
            )
          ) {
            divClass = 'flex space-x-[.5rem] w-full items-start';
          }
          return (
            <div className={divClass} key={`container_${graphs[0].key}`}>
              {graphs.map((graph) => renderInput(graph))}
              {nextChildren(graphs)}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export default GeneralInformationForm;
